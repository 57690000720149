import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/common/layout/layout";
import SectionLayout from "../components/common/layout/sectionLayout";
import {Col} from "react-bootstrap";
import * as headerStyle from "../styles/header.module.scss";

const NotFoundPage = () => (
  <>
    <Layout>
      <Seo title="404: Not found" />
      <SectionLayout header="Ops, ein 404 Fehler" colorTheme="dark">
        {/* Header Gap needed because no pages header with Picture is used */}
        <div className={headerStyle.headerGap} />
        <Col>
          <h3 className="p-5">Diese Seite existiert leider nicht.</h3>
        </Col>
      </SectionLayout>
    </Layout>
  </>
)

export default NotFoundPage
